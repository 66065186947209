<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>Editar usuário</b-card-title>
      </b-card-header>
       <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Nome"
            rules="required"
          >
            <b-form-group 
              label="Nome"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Joao da Silva"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>        

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>     
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BCardHeader, BCardTitle
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref,onBeforeMount } from '@vue/composition-api'
import { required, email, confirmed, min} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import axios from '@axios'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCard,
    BCardHeader, 
    BCardTitle,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      confirmed,
      min
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      email: ''
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    const resetUserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    onBeforeMount(() => {
      axios
      .get('usuarios/'+router.currentRoute.params.id)
      .then(response => {
        const { data } = response.data  
        userData.value = data
      })
      .catch(error => reject(error))  
    })

    function onSubmit() {
     axios
      .put('usuarios/'+router.currentRoute.params.id,{
        ...userData.value
      })
      .then(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Alterar usuário',
            icon: 'CheckIcon',
            variant: 'success',
            text: `Alterado com sucesso!`,
          },
        })
        router.go(-1)
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Alterar usuário',
            icon: 'XIcon',
            variant: 'danger',
            text: `Algo deu errado ):`,
          },
        })
      })  
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
